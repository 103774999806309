/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react'
import ReactQuill from 'react-quill'

import t from 'prop-types'
import { Card, Form, Grid, Image, Button, Icon } from 'semantic-ui-react'

import useCustomers from '~/hooks/useCustomers'

const CreateCategories = ({
  show,
  myRef,
  create,
  onClick,
  category,
  onChange,
  isLoading,
  uploadImage,
}) => {
  const { getCustomers, listNameCustomers } = useCustomers()

  useEffect(() => {
    getCustomers({ limit: 20000, isPrivate: true })
  }, []) // eslint-disable-line

  const avatarImg =
    category.avatar !== null
      ? category.avatar?.src || category.avatar
      : 'https://react.semantic-ui.com/images/avatar/large/matthew.png'

  return (
    <Card
      centered
      fluid
      style={{ marginTop: 10, display: show ? 'block' : 'none' }}
    >
      <Card.Content>
        <Grid columns={2} relaxed>
          <Grid.Column width={3}>
            <Card>
              <Image size="medium" verticalAlign="middle" src={avatarImg} />
              <Card.Content>
                <input
                  ref={myRef}
                  type="file"
                  id="uploadImage"
                  accept="image/*"
                  onChange={uploadImage}
                  style={{ display: 'none' }}
                />
                <Button
                  icon="upload"
                  content="Upload"
                  onClick={onClick}
                  labelPosition="right"
                />
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={13}>
            <Form>
              <Form.Input
                type="text"
                value={category.name || ''}
                label="Nome (Obrigatório)"
                placeholder="Ex: Pesquisas"
                onChange={(e, target) => onChange('name', target.value)}
              />
              <div className="field">
                <Form.Checkbox
                  label="Tem consultor líder?"
                  type="checkbox"
                  checked={category.has_consultant || false}
                  onChange={(e, target) =>
                    onChange('has_consultant', target.checked)
                  }
                />
              </div>

              <Form.Checkbox
                label="Privado"
                checked={category.has_private_customer || false}
                onChange={(e, { checked }) =>
                  onChange('has_private_customer', checked)
                }
              />

              {category.has_private_customer && (
                <Form.Select
                  fluid
                  search
                  selection
                  options={listNameCustomers || []}
                  placeholder="Selecione o cliente privado"
                  value={category.customer_id || ''}
                  onChange={(e, { value }) => onChange('customer_id', value)}
                />
              )}

              <Form.Select
                label="Exibição da Categoria"
                value={category.available_in}
                options={[
                  { key: 'public', value: 'public', text: 'Público' },
                  { key: 'private', value: 'private', text: 'Privado' },
                ]}
                onChange={(e, { value }) => onChange('available_in', value)}
              />

              <div className="field">
                <label>Descrição (Obrigatório)</label>
                <ReactQuill
                  value={category.description}
                  onChange={value => onChange('description', value)}
                />
              </div>
            </Form>
          </Grid.Column>
        </Grid>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button
          icon
          positive
          onClick={create}
          loading={isLoading}
          disabled={isLoading}
          labelPosition="left"
        >
          <Icon name="check" /> Salvar
        </Button>
      </Card.Content>
    </Card>
  )
}

CreateCategories.propTypes = {
  show: t.bool,
  create: t.func,
  isLoading: t.bool,
  onClick: t.func,
  onChange: t.func,
  uploadImage: t.func,
  myRef: t.objectOf(t.any),
  category: t.objectOf(t.any),
}

export default CreateCategories
