import React from 'react'

import { Grid, Card, CardContent } from 'semantic-ui-react'

function SharedLink({ sharedLink, subId, completed, clicks, ctr }) {
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={8}>
          <Card fluid>
            <CardContent>
              <ul>
                <li>
                  Link: <strong>{sharedLink}</strong>
                </li>
                <li>
                  Sub-id: <strong>{subId}</strong>
                </li>
                <li>
                  Total finalizadas: <strong>{completed}</strong>
                </li>
                <li>
                  Cliques: <strong>{clicks}</strong>
                </li>
                <li>
                  Taxa de cliques(CTR): <strong>{ctr}</strong>
                </li>
              </ul>
            </CardContent>
          </Card>
        </Grid.Column>
        <Grid.Column width={11} />
      </Grid.Row>
    </Grid>
  )
}

export default SharedLink
