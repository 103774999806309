import { useCallback, useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

const subcategoryDefault = {
  uid: '',
  title: '',
  avatar: '',
  fix_time: 0,
  user_value: 0,
  mission_commission_percent: 0,
  image_path: '',
  description: '',
  participants: 0,
  expiring_time: 0,
  has_commission: false,
  show_only_if_badge: false,
  has_check_availability: false,
  has_bonus: false,
  has_forms: false,
  has_photo: false,
  has_pos: false,
  customer_value: 0,
  user_value_prime: null,
  user_value_black: null,
  can_restart: false,
  no_location: false,
  has_scanner: false,
  can_multiple: false,
  has_timeline: false,
  user_value_bonus: 0,
  has_attachment: false,
  time_to_appreciate: 0,
  has_import_image: false,
  customer_value_bonus: 0,
  has_custom_status: false,
  has_auto_approval: false,
  has_value_variable: false,
  has_value_prime: false,
  has_value_black: false,
  is_expiring_weekend: false,
  has_value_percentage: false,
  has_import_document: false,
  should_inactivate_when_reproved: false,
  has_signature: false,
}

export default function useSubCategories() {
  const [subcategory, setSubcategory] = useState(subcategoryDefault)
  const [subcategories, setSubcategories] = useState([])
  const [loadingSubcategories, setLoading] = useState(false)
  const [subcategoriesCount, setSubcategoriesCount] = useState(0)
  const [subcategoriesOptions, setSubcategoriesOptions] = useState([])
  const [customFields, setCustomFields] = useState([])
  // const [deliveryPlacesCustomFields, setDeliveryPlacesCustomFields] = useState(
  //   []
  // )

  // Migrar para uma outra pagina
  // function onChangeDeliveryPlaceCustomFieldInput(key, value, index) {
  //   const registers = [...deliveryPlacesCustomFields]
  //   registers[index][key] = value
  //   setDeliveryPlacesCustomFields([...registers])
  // }

  // function onRemoveDeliveryPlaceCustomField(event, position) {
  //   event.preventDefault()
  //   const registers = [...deliveryPlacesCustomFields]
  //   registers.splice(position, 1)
  //   setDeliveryPlacesCustomFields([...registers])
  // }

  // function onAddDeliveryPlaceCustomField(event) {
  //   event.preventDefault()
  //   setDeliveryPlacesCustomFields([
  //     ...deliveryPlacesCustomFields,
  //     {
  //       name: '',
  //       required: false,
  //       type: '',
  //     },
  //   ])
  // }

  // function getCustomFieldsFilled() {
  //   return customFields.filter(item => {
  //     if (item.name && item.name.length === 0) {
  //       return false
  //     }
  //     return true
  //   })
  // }

  // function getDeliveryPlacesCustomFieldsFilled() {
  //   console.log(deliveryPlacesCustomFields)
  //   return deliveryPlacesCustomFields.filter(item => {
  //     if (item.name && item.type) {
  //       return true
  //     }

  //     return false
  //   })
  // }

  function onAddCustomField() {
    setCustomFields([...customFields, { name: '' }])
  }

  function onRemoveCustomField(position) {
    const registers = [...customFields]
    registers.splice(position, 1)
    setCustomFields([...registers])
  }

  function onChangeInputValueCustomField(index, value) {
    const registers = customFields
    registers[index].value = value
    setCustomFields([...registers])
  }

  function onChangeCustomFieldInput(value, index) {
    const registers = [...customFields]
    registers[index].name = value
    setCustomFields([...registers])
  }

  const getSubCategoryById = useCallback(async id => {
    try {
      setLoading(true)
      const res = await api.get(`missions-main/${id}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      if (res.data.custom_fields) {
        setCustomFields(res.data.custom_fields.fields)
      }

      return setSubcategory(prev => ({ ...prev, ...res.data }))
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  const getSubCategories = useCallback(async params => {
    try {
      setLoading(true)
      const res = await api.get('missions-main', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setSubcategoriesOptions(
        res.data.missionsMains.map(subcategorie => ({
          text: subcategorie.title,
          value: subcategorie.uid,
        }))
      )

      setSubcategoriesCount(res.data.count)
      setSubcategories(res.data.missionsMains)
      return res.data.missionsMains
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }, [])

  async function onSubmitSubcategory(data) {
    try {
      setLoading(true)

      if (
        data.check_in_check_out &&
        typeof Number(data.check_distance) !== 'number'
      ) {
        throw new Error('Defina um raio de distância para Check In')
      }

      const res = await api.post('missions-main', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      setSubcategory(res.data[0])
      return Message().success('Subcategoria criada com sucesso!')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      getSubCategories()
      setLoading(false)
    }
  }

  async function onUpdateSubcategory(data) {
    try {
      setLoading(true)

      if (
        data.check_in_check_out &&
        typeof Number(data.check_distance) !== 'number'
      ) {
        throw new Error('Defina um raio de distância para Check In')
      }

      const res = await api.put('missions-main', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setSubcategories(prevState =>
        prevState.map(prev => {
          if (prev.id === res.data[0].id) {
            return res.data[0]
          }

          return prev
        })
      )

      setSubcategory(res.data[0])

      if (data.deleted) {
        Message().success('Subcategoria deletada com sucesso')
        return getSubCategories({
          limit: 10,
          offset: 0,
          categoryId: data.category_missions_main_id,
        })
      }

      return Message().success('Subcategoria atualizada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    subcategory,
    subcategories,
    setSubcategory,
    onSubmitSubcategory,
    onUpdateSubcategory,
    subcategoriesCount,
    getSubCategories,
    getSubCategoryById,
    loadingSubcategories,
    subcategoriesOptions,
    customFields,
    onAddCustomField,
    onRemoveCustomField,
    onChangeCustomFieldInput,
    onChangeInputValueCustomField,
    setCustomFields,
  }
}
