class Spreadsheet {
  static async mapRowsToObject(rows) {
    const header = rows[0]
    const newRows = []

    for (let index = 1; index < rows.length; index += 1) {
      const line = rows[index]
      const rowValues = {}
      const rowsItems = []

      for (let x = 0; x < header?.length; x += 1) {
        rowsItems.push({ ...rowValues, [header[x]]: line[x] })
      }

      const result = rowsItems.reduce((obj, item) => {
        const [key, value] = Object.entries(item)[0]
        obj[key] = value
        return obj
      }, {})

      newRows.push(result)
    }

    const items = await Promise.all(newRows)

    rows.shift()
    return items
  }
}

export default Spreadsheet
