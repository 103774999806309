import React, { useState } from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Card, Feed, Icon, Popup } from 'semantic-ui-react'

import {
  iconColor,
  missionActionLabel,
  missionActionIcon,
} from '~/utils/feedsActions'

import RemoveModal from './components/RemoveModal'
import UpdateModal from './components/UpdateModal'

const Feeds = ({
  onClick,
  items,
  missionsUsersId,
  onSubmitUpdate,
  onClickOpenEdit,
  onClickCloseEdit,
  openEdit,
  onClickOpenRemove,
  openRemove,
  onClickCloseRemove,
  onSubmitRemove,
}) => {
  const [itemData, setItemData] = useState(null)

  const handleClickOpenModal = (item, type) => {
    setItemData(item)

    if (type === 'edit') {
      onClickOpenEdit()
    }

    if (type === 'remove') {
      onClickOpenRemove()
    }
  }

  return (
    <>
      <Card fluid style={{ height: 580, maxHeight: 600, overflow: 'auto' }}>
        <Card.Content>
          <Card.Header>{items.length} Feeds</Card.Header>
          <Feed>
            {items.length > 0 &&
              items?.map((item, index) => {
                const { lat, lng, action } = item
                return (
                  <Feed.Event key={String(index)}>
                    <Feed.Label>
                      <Icon
                        circular
                        inverted
                        style={{ marginRight: 10 }}
                        color={iconColor(action)}
                        name={missionActionIcon[action]}
                      />
                    </Feed.Label>
                    <Feed.Content>
                      <Feed.Summary>
                        <Feed.User onClick={() => onClick({ lat, lng })}>
                          {missionActionLabel[action]}
                        </Feed.User>
                        <Feed.Date
                          content={format(
                            new Date(item.created_at),
                            'dd/MM/yyyy HH:mm'
                          )}
                        />
                      </Feed.Summary>
                    </Feed.Content>
                    <Popup
                      content="Editar Feed"
                      trigger={
                        <Icon
                          name="edit"
                          link
                          color="blue"
                          bordered
                          inverted
                          onClick={() => handleClickOpenModal(item, 'edit')}
                        />
                      }
                    />

                    <Popup
                      content="Remover Feed"
                      trigger={
                        <Icon
                          disabled={action !== 'finish'}
                          link={action === 'finish'}
                          name="trash"
                          color="red"
                          bordered
                          inverted
                          onClick={() => handleClickOpenModal(item, 'remove')}
                        />
                      }
                    />
                  </Feed.Event>
                )
              })}
          </Feed>
        </Card.Content>
      </Card>
      <UpdateModal
        open={openEdit}
        onClose={onClickCloseEdit}
        itemData={itemData}
        missionsUsersId={missionsUsersId}
        onSubmitUpdate={onSubmitUpdate}
      />

      <RemoveModal
        open={openRemove}
        onClose={onClickCloseRemove}
        feedData={itemData}
        onSubmitRemove={onSubmitRemove}
      />
    </>
  )
}

Feeds.propTypes = {
  onClick: t.func,
  items: t.arrayOf(t.any),
  missionsUsersId: t.string,
  onSubmitUpdate: t.func,
  onClickOpenEdit: t.func,
  onClickCloseEdit: t.func,
  openEdit: t.bool,
  onClickOpenRemove: t.func,
  openRemove: t.bool,
  onClickCloseRemove: t.func,
  onSubmitRemove: t.func,
}

export default Feeds
