import React from 'react'
import ReactMapGl from 'react-map-gl'

import t from 'prop-types'

import MarkerIcon from '~/components/MapsItems/MarkerIcon'
import { MAPBOX_TOKEN } from '~/config/env'

export function Map({ viewport, onChange, items }) {
  return (
    <ReactMapGl
      {...viewport}
      width="100%"
      height={300}
      onViewportChange={onChange}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
    >
      {items?.map((item, index) => (
        <MarkerIcon key={`item-${Number(index)}`} {...item} index={index} />
      ))}
    </ReactMapGl>
  )
}

Map.propTypes = {
  onChange: t.func,
  items: t.arrayOf(t.any),
  viewport: t.objectOf(t.any),
}
