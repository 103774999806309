/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'

import dayjs from 'dayjs'
import t from 'prop-types'
import { Icon, Button, Modal, Popup, Table, Label } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useMissions from '~/hooks/useMissions'

import { EXPORT_STATUS } from '../../../helpers/type'

export function ListExports({ open, onClose }) {
  const { getExportMissionsLaunched, missionsLaunchedExported } = useMissions()

  const [pagination, setPagination] = useState({
    limit: 10,
    activePage: 1,
    offset: 0,
  })

  const COLUMNS = ['ID', 'URL', 'Status', 'Filtros', 'Criado em', 'Baixar']

  const renderItem = item => (
    <Table.Row key={`${item.id}`}>
      <Table.Cell>{item.id}</Table.Cell>
      <Table.Cell>{item.url}</Table.Cell>
      <Table.Cell>
        <Label color={item.status === 'DONE' ? 'green' : 'yellow'}>
          {EXPORT_STATUS[item.status]}
        </Label>
      </Table.Cell>
      <Table.Cell>
        <Popup
          hoverable
          content={
            <div style={{ whiteSpace: 'pre-wrap', maxWidth: '300px' }}>
              {Object.keys(item.filters).map(key => {
                const value = item.filters[key]

                if (Array.isArray(value)) {
                  let valueToShow = value

                  if (key === 'cities') {
                    valueToShow = value.map(
                      citie => `${citie.cityName}-${citie.uf}`
                    )
                  }

                  return (
                    <div key={key}>
                      <strong>{key}:</strong>
                      <p>{valueToShow.join(', ')}</p>
                    </div>
                  )
                }

                if (['iniCreate', 'finCreate'].includes(key)) {
                  return (
                    <div key={key}>
                      <strong>{key}:</strong>{' '}
                      <p>{dayjs(value).format('DD/MM/YYYY')}</p>
                    </div>
                  )
                }

                return (
                  <div key={key}>
                    <strong>{key}:</strong> <p>{value}</p>
                  </div>
                )
              })}
            </div>
          }
          trigger={<span style={{ cursor: 'pointer' }}>Ver Detalhes</span>}
        />
      </Table.Cell>
      <Table.Cell>
        {dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Icon
          name="download"
          link={item.status === 'DONE'}
          disabled={item.status !== 'DONE'}
          onClick={() => window.open(item.url, '_blank')}
        />
      </Table.Cell>
    </Table.Row>
  )

  function handlePaginationChange(e, { activePage }) {
    const offset = (activePage - 1) * pagination.limit

    setPagination(prevState => ({
      ...prevState,
      activePage,
      offset,
    }))

    return getExportMissionsLaunched({
      offset,
      limit: pagination.limit,
    })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      onMount={() => getExportMissionsLaunched(pagination)}
    >
      <Modal.Header>Lista de Missões Lançadas Exportadas</Modal.Header>
      <Modal.Content>
        <TableComponent
          showEllipsis
          count={missionsLaunchedExported.count}
          data={missionsLaunchedExported.result}
          columns={COLUMNS}
          showFirstAndLastNav
          emptyText={{
            icon: 'check circle outline',
            text: 'Nenhuma Missão Lançada foi Exportada',
          }}
          showPreviousAndNextNav
          totalCount={missionsLaunchedExported.count}
          limit={pagination.limit}
          activePage={pagination.activePage}
          offset={pagination.offset}
          handlePaginationChange={handlePaginationChange}
          renderItem={renderItem}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Fechar" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  )
}

ListExports.propTypes = {
  open: t.bool,
  onClose: t.func,
}
