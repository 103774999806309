import { useCallback, useState } from 'react'

import dayjs from 'dayjs'

import api from '~/services/api'
import { getNoEmptyFields } from '~/utils/getNoEmptyFields'
import Message from '~/utils/messages'

export default function useMissions() {
  const [count, setCount] = useState(15)
  const [missions, setMissions] = useState([])
  const [totalReplace, setTotalReplace] = useState(0)
  const [loadingReplace, setLoadingReplace] = useState(false)
  const [loadingMissions, setLoadingMissions] = useState(false)
  const [joinedMissions, setJoinedMissions] = useState([])
  const [loadingMissionsUpdate, setLoadingMissionsUpdate] = useState(false)
  const [activeMissionsByCustomer, setActiveMissionsByCustomer] = useState(null)
  const [
    customFieldsAnswersOfMissionSelected,
    setCustomFieldsAnswersOfMissionSelected,
  ] = useState([])
  const [missionsLaunchedExported, setMissionsLaunchedExported] = useState([])

  async function getMissionsOpen(query) {
    setLoadingMissions(true)
    try {
      const res = await api.get('missions/available', {
        params: query,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const joinMissions = res.data.data.filter((value, index, self) => {
        const uniqueId = self.map(i => i.uid)
        return uniqueId.indexOf(value.uid) === index
      })

      setJoinedMissions(joinMissions)
      setCount(res.data.data[0].total)
      return setMissions(res.data.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMissions(false)
    }
  }

  async function onUpdateMission(data) {
    setLoadingMissionsUpdate(true)
    const {
      status,
      uid,
      identifier,
      name,
      expired_at,
      activated_at,
      description,
      customer_id,
      missions_main_id,
      place,
      lat,
      lng,
      uf,
      city,
      deleted,
      postal_code,
      custom_distance,
    } = data
    try {
      const dataModified = {
        status,
        uid,
        lat,
        lng,
        identifier,
        name,
        uf,
        city,
        postal_code,
        deleted,
        activated_at,
        expired_at,
        description,
        customer_id,
        street: data.place,
        missions_main_id,
        place,
        custom_distance,
      }

      if (data.custom_fields) {
        dataModified.custom_fields = data.custom_fields.fields
      }

      const res = await api.put('missions/admin', dataModified, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      Message().success('Missão atualizada com sucesso!')
      return res.data[0]
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMissionsUpdate(false)
    }
  }

  async function updateAllMissions(params) {
    try {
      setLoadingMissions(true)

      const res = await api.put(
        'missions/admin/update-batch',
        { ...params },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success(
        'Adicionado na fila de edição em massa com sucesso!'
      )
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMissions(false)
    }
  }

  const getReplaceMissions = useCallback(async params => {
    try {
      setLoadingReplace(true)
      const res = await api.get('missions/admin/replace/total', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setTotalReplace(res.data.total)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingReplace(false)
    }
  }, [])

  async function onSubmitReplaceMissions(data) {
    try {
      setLoadingReplace(true)
      const res = await api.post('missions/admin/replace', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return setTotalReplace(res.data.total)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingReplace(false)
    }
  }

  async function getActiveMissionsByCustomerId(params) {
    try {
      setLoadingMissions(true)

      const res = await api.get('missions/active/admin', {
        params,
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const mappedData = res.data?.map(item => ({
        text: item.name,
        value: item.uid,
      }))

      return setActiveMissionsByCustomer(mappedData)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMissions(false)
    }
  }

  async function createExportMissionsLaunched(params) {
    try {
      setLoadingMissions(true)

      const filters = getNoEmptyFields(params)

      const startedAt = filters.iniCreate || filters.iniExpired
      if (startedAt) {
        filters.startedAt = dayjs(startedAt).format('YYYY-MM-DD')
        delete filters.iniCreate
        delete filters.iniExpired
      }

      const endAt = filters.finCreate || filters.finExpired
      if (endAt) {
        filters.endAt = dayjs(endAt).format('YYYY-MM-DD')
        delete filters.finCreate
        delete filters.finExpired
      }

      const res = await api.post('missions/export/launched', filters, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      return Message().success(
        'A exportação está sendo gerada. Estará disponível na listagem em minutos'
      )
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMissions(false)
    }
  }

  async function getExportMissionsLaunched(params) {
    try {
      setLoadingMissions(true)

      const res = await api.get('missions/export/launched', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setMissionsLaunchedExported(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingMissions(false)
    }
  }

  return {
    count,
    missions,
    totalReplace,
    loadingReplace,
    loadingMissions,
    joinedMissions,
    getMissionsOpen,
    updateAllMissions,
    getReplaceMissions,
    onUpdateMission,
    loadingMissionsUpdate,
    onSubmitReplaceMissions,
    getActiveMissionsByCustomerId,
    activeMissionsByCustomer,
    setActiveMissionsByCustomer,
    customFieldsAnswersOfMissionSelected,
    setCustomFieldsAnswersOfMissionSelected,
    createExportMissionsLaunched,
    getExportMissionsLaunched,
    missionsLaunchedExported,
  }
}
