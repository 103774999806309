import { useState } from 'react'

import dayjs from 'dayjs'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMissionsFeeds() {
  const [feeds, setFeeds] = useState([])
  const [usersFeeds, setUsersFeeds] = useState([])
  const [countFeeds, setCountFeeds] = useState(15)
  const [loadingFeeds, setLoadingFeeds] = useState(false)

  async function getMissionsFeeds(id) {
    try {
      setLoadingFeeds(true)
      const res = await api.get(
        `missions-feeds/missions-users/admin?missionsUsersId=${id}`,
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setCountFeeds(res.data.length)
      return setFeeds(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFeeds(false)
    }
  }

  async function getMissionsFeedsByUserId({ userId, limit }) {
    try {
      setLoadingFeeds(true)
      const res = await api.get('missions-feeds/users', {
        params: {
          userId,
          limit,
        },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setUsersFeeds(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFeeds(false)
    }
  }

  async function updateMissionsFeeds(params) {
    try {
      setLoadingFeeds(true)

      delete params.place

      params.date = dayjs(params.date).format('YYYY-MM-DD')

      const res = await api.put('missions-feeds/admin', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setFeeds(prevState =>
        prevState.map(prev => {
          if (prev.uid === res.data[0].uid) {
            return res.data[0]
          }

          return prev
        })
      )

      return Message().success('Missions Feeds atualizado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFeeds(false)
    }
  }

  async function removeMissionsFeeds(params) {
    try {
      setLoadingFeeds(true)

      const res = await api.delete(`missions-feeds/admin/${params.uid}`, {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setFeeds(prevState =>
        prevState.filter(prev => prev.uid !== res.data[0].uid)
      )

      return Message().success('Feed removido com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingFeeds(false)
    }
  }

  return {
    feeds,
    usersFeeds,
    countFeeds,
    loadingFeeds,
    getMissionsFeedsByUserId,
    getMissionsFeeds,
    updateMissionsFeeds,
    removeMissionsFeeds,
  }
}
