import React from 'react'

import { Segment, Dimmer, Loader } from 'semantic-ui-react'

export default function Loading({ customText }) {
  return (
    <Segment>
      <Dimmer active inverted>
        <Loader inverted>{customText || 'Carregando...'}</Loader>
      </Dimmer>
    </Segment>
  )
}
