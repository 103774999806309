import React from 'react'
import { useForm, Controller } from 'react-hook-form'

import t from 'prop-types'
import { Button, Checkbox, Form, Grid, Modal } from 'semantic-ui-react'

export function SelectColumnsExport({
  columns,
  open,
  onClose,
  onSubmitSelectedColumns,
}) {
  const { handleSubmit, control, reset } = useForm()

  const columnsMapToAPI = {
    COD: 'mission_key',
    Identificador: 'identifier',
    Cliente: 'customer_name',
    Categoria: 'category',
    Nome: 'mission_name',
    Criador: 'creator',
    'Criado Em': 'created_at',
    'Ativado Em': 'activated_at',
    'Expira Em': 'expired_at',
    Status: 'status',
    Reativações: 'times_reactivated',
  }

  function onSubmit(data) {
    const selectedColumns = Object.entries(data)
      .filter(([_, value]) => value)
      .map(([key]) => columnsMapToAPI[key])

    onSubmitSelectedColumns(selectedColumns)

    reset()
  }

  function handleOnClose() {
    onClose()
    reset()
  }

  return (
    <Form>
      <Modal open={open} size="small">
        <Modal.Header>
          Selecione as Colunas que Deverão Constar na Exportação
        </Modal.Header>
        <Modal.Content>
          <Grid columns={3} stackable>
            {columns.map(
              column =>
                column.length > 0 && (
                  <Grid.Column key={column}>
                    <Controller
                      name={column}
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Form.Field
                          control={Checkbox}
                          label={{ children: column }}
                          checked={field.value}
                          onChange={(e, { checked }) => field.onChange(checked)}
                        />
                      )}
                    />
                  </Grid.Column>
                )
            )}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Cancelar"
            onClick={() => handleOnClose()}
            type="button"
          />
          <Button content="Exportar" primary onClick={handleSubmit(onSubmit)} />
        </Modal.Actions>
      </Modal>
    </Form>
  )
}

SelectColumnsExport.propTypes = {
  columns: t.arrayOf(t.string),
  open: t.bool,
  onClose: t.func,
  onSubmitSelectedColumns: t.func,
}
