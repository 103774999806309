import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'

import t from 'prop-types'
import { Card, Icon, Form, Grid, Button } from 'semantic-ui-react'

import CustomFields from '~/pages/Categories/Subcategories/components/CustomFields'

import { Activities, Appearance, Info } from './components'
import { missionsMainProps } from './missionsMainProps'

import '~/assets/css/react-draft-wysiwyg.css'

export function FormSubcategory({
  subcategory,
  show,
  loading,
  onSubmit,
  customFields,
  onAddCustomField,
  onRemoveCustomField,
  onChangeCustomFieldInput,
}) {
  const form = useForm()
  const [openField, setOpenField] = useState({})

  function onChange(e, { name, value }) {
    return form.setValue(name, value)
  }

  function onChangeCheckbox(e, { name, checked }) {
    setOpenField(prev => ({ ...prev, [name]: checked }))

    return form.setValue(name, checked)
  }

  useEffect(() => {
    if (subcategory?.uid) {
      setOpenField(prev => ({ ...prev, ...subcategory }))
      form.reset(subcategory)
    }
  }, [subcategory, form])

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <Card
        centered
        fluid
        style={{ marginTop: 10, display: show ? 'block' : 'none' }}
      >
        <Card.Content>
          <Grid columns={4} relaxed>
            <Info
              getValues={form.getValues}
              openField={openField}
              onChange={onChange}
            />
            <Activities
              getValues={form.getValues}
              onChange={onChange}
              openField={openField}
              onChangeCheckbox={onChangeCheckbox}
            />
            <Appearance getValues={form.getValues} onChange={onChange} />
          </Grid>

          <CustomFields
            onAddCustomField={onAddCustomField}
            onRemoveCustomField={onRemoveCustomField}
            onChangeCustomFieldInput={onChangeCustomFieldInput}
            customFields={customFields}
          />

          {/* {openField.has_delivery_places && (
            <DeliveryPlaceCustomFields
              onAddDeliveryPlaceCustomField={onAddDeliveryPlaceCustomField}
              deliveryPlacesCustomFields={deliveryPlacesCustomFields}
              onRemoveDeliveryPlaceCustomField={
                onRemoveDeliveryPlaceCustomField
              }
              onChangeDeliveryPlaceCustomFieldInput={
                onChangeDeliveryPlaceCustomFieldInput
              }
            />
          )} */}

          <Grid columns={1} relaxed>
            <Grid.Column width={16}>
              <div className="field">
                <label>Descrição (Obrigatório)</label>
                <ReactQuill
                  value={form.getValues('description') || ''}
                  onChange={value => form.setValue('description', value)}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Card.Content>

        <Card.Content extra textAlign="right">
          <Button
            icon
            positive
            type="submit"
            loading={loading}
            disabled={loading}
            labelPosition="left"
          >
            <Icon name="check" /> Salvar
          </Button>
        </Card.Content>
      </Card>
    </Form>
  )
}

FormSubcategory.propTypes = {
  show: t.bool,
  loading: t.bool,
  onSubmit: t.func,
  onAddCustomField: t.func,
  onRemoveCustomField: t.func,
  onChangeCustomFieldInput: t.func,
  subcategory: missionsMainProps.values,
  customFields: t.arrayOf(t.objectOf({})),
}
