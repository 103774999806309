import React, { useState, useRef, useEffect } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc'

import filesize from 'filesize'
import t from 'prop-types'
import { Image, Button, Checkbox } from 'semantic-ui-react'

import LoadingPage from '~/components/LoadingPage'
import { useAuth } from '~/contexts/auth'
import usePhotos from '~/hooks/usePhotos'
import Main from '~/pages/Main'

import SendImages from './SendImages'
import {
  Container,
  Item,
  DragItem,
  TitleItem,
  IconItem,
  ContainerButton,
  HeaderContainer,
  BottomContainer,
} from './styles'

function Photos({ id, userId, missionId }) {
  const { user } = useAuth()
  const uploadRef = useRef()
  const [limit, setLimit] = useState(25)
  const [show, setShow] = useState(false)
  const [images, setImages] = useState([])
  const [selected, setSelected] = useState([])
  const [lightBox, setLightBox] = useState(false)
  const [sendImage, setSendImage] = useState(false)
  const [showRotate, setShowRotate] = useState(false)
  const [direction, setDirection] = useState({ rotate: 0, id: null })
  const {
    onRotatePhoto,
    loadingPhotos,
    photos,
    getPhotos,
    onReorderPhotos,
    setPhotos,
    onRemovePhoto,
  } = usePhotos()

  function isChecked(item) {
    return selected.findIndex(e => e === item) !== -1
  }

  function onLoadingMore(e) {
    e.preventDefault()
    const newLimit = limit + 20
    setLimit(newLimit)
    return onRefresh(newLimit)
  }

  function onClickSelection() {
    return setSelected(photos.length === selected.length ? [] : photos)
  }

  function onClickSelectedItem(item) {
    const index = selected.findIndex(i => i === item)

    if (index > -1) {
      return onRemoveSelected(item)
    }

    return setSelected(prevState => [...prevState, item])
  }

  function onRemoveImage(imageItem) {
    return setImages(images.filter(i => i !== imageItem))
  }

  function onRemoveSelected(selectedItem) {
    return setSelected(selected.filter(i => i !== selectedItem))
  }

  async function onRemoveSelectedItems() {
    await Promise.all(selected.map(i => onRemovePhoto(i.uid)))

    setSelected([])

    return onRefresh(limit)
  }

  function onRefresh(limitMore = limit) {
    return getPhotos({ id, limit: limitMore })
  }

  function onRotateImage({ rotate, imageId }) {
    setShowRotate(true)
    setDirection({ id: imageId, rotate })
  }

  async function onUploadImages(e) {
    const { files } = e.target
    let arr = []

    for (let i = 0; i < files.length; i += 1) {
      arr = [
        ...arr,
        {
          ...files[i],
          uniqueId: i,
          src: files[i],
          table: 'photos',
          missions_users_id: id,
          order: images.length + 1,
          user_id: userId,
          size: filesize(files[i].size),
          mission_id: missionId,
          preview: URL.createObjectURL(files[i]),
        },
      ]
    }

    setImages(arr)
    return setSendImage(true)
  }

  async function onSubmitRotate(data) {
    await onRotatePhoto(data)

    return setDirection({ id: null, rotate: 0 })
  }

  const DragHandle = SortableHandle(() => (
    <DragItem>
      <IconItem name="arrows alternate" />
      <TitleItem>Clique e Arraste para Ordenar</TitleItem>
    </DragItem>
  ))

  const SortableItem = SortableElement(({ value }) => (
    <Item>
      <DragHandle />
      <Image
        fluid
        key={value.uid}
        src={value.path}
        onClick={() => setLightBox(true)}
        style={{
          animation: '4s linear',
          transform: `rotate(${
            direction.id === value.uid ? direction.rotate : 0
          }deg)`,
        }}
      />
      <ContainerButton>
        <Checkbox
          fitted
          id={value.uid}
          checked={isChecked(value)}
          label={isChecked(value) && 'Selecionado'}
          onChange={() => onClickSelectedItem(value)}
        />
        {direction.id === value.uid && showRotate && (
          <Button
            icon="check"
            color="green"
            content="Aplicar"
            onClick={() =>
              onSubmitRotate({
                id,
                fileId: value.uid,
                rotate: direction.rotate,
              })
            }
          />
        )}
        <Button.Group>
          <Button
            icon="angle left"
            onClick={() => onRotateImage({ rotate: 180, imageId: value.uid })}
          />
          <Button
            icon="angle down"
            onClick={() => onRotateImage({ rotate: -90, imageId: value.uid })}
          />
          <Button
            icon="angle up"
            onClick={() => onRotateImage({ rotate: 90, imageId: value.uid })}
          />
          <Button
            icon="angle right"
            onClick={() => onRotateImage({ rotate: 0, imageId: value.uid })}
          />
        </Button.Group>
      </ContainerButton>
    </Item>
  ))

  const SortableCont = SortableContainer(({ children }) => (
    <Container>{children}</Container>
  ))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setShow(true)

    return setPhotos(prevState => arrayMove(prevState, oldIndex, newIndex))
  }

  useEffect(() => {
    onRefresh()
  }, []) //eslint-disable-line

  return (
    <Main>
      <HeaderContainer>
        <Button
          loading={loadingPhotos}
          disabled={loadingPhotos}
          onClick={onClickSelection}
          color={selected.length > 0 ? 'grey' : 'blue'}
          content={
            selected.length > 0 ? 'Deselecionar tudo' : 'Selecionar tudo'
          }
        />
        {selected.length > 0 && (
          <Button
            negative
            loading={loadingPhotos}
            disabled={loadingPhotos}
            onClick={onRemoveSelectedItems}
            content={`Remover ${selected.length} Foto(s)`}
          />
        )}
        {show && (
          <Button
            color="purple"
            loading={loadingPhotos}
            onClick={() => onReorderPhotos({ id, data: { items: photos } })}
          >
            Reordenar
          </Button>
        )}
        {user.role_name === 'Admin' && (
          <>
            <input
              multiple
              type="file"
              id="uploadRef"
              ref={uploadRef}
              onChange={onUploadImages}
              accept=".png, .jpg, .jpeg"
              style={{ display: 'none' }}
            />
            <Button
              loading={loadingPhotos}
              content="Adicionar Imagem"
              onClick={() => uploadRef.current.click()}
            />
          </>
        )}
      </HeaderContainer>

      {loadingPhotos && <LoadingPage />}

      {!loadingPhotos && (
        <SortableCont axis="xy" onSortEnd={onSortEnd} useDragHandle>
          {photos.map((value, index) => (
            <SortableItem key={`i-${value}`} index={index} value={value} />
          ))}
        </SortableCont>
      )}

      <BottomContainer>
        <Button onClick={onLoadingMore} content="Carregar Mais..." />
      </BottomContainer>

      <ModalGateway>
        {lightBox ? (
          <Modal onClose={() => setLightBox(false)}>
            <Carousel
              showThumbnails
              views={photos.map(i => ({ ...i, source: i.path }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <SendImages
        id={id}
        open={sendImage}
        images={images}
        onRefresh={onRefresh}
        onRemove={onRemoveImage}
        onClose={() => setSendImage(false)}
      />
    </Main>
  )
}

Photos.propTypes = {
  id: t.string.isRequired,
  userId: t.string.isRequired,
  missionId: t.string.isRequired,
}

export default Photos
