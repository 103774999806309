import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Button, Confirm, Tab } from 'semantic-ui-react'

import LoadingPage from '~/components/LoadingPage'
import ReproveList from '~/components/Modal/Reprove'
import useRegisters from '~/hooks/useRegisters'
import useSharedLink from '~/hooks/useSharedLink'
import Main from '~/pages/Main'
import message from '~/utils/messages'
import { registerStatus } from '~/utils/types'

import Activities from './components/Activities'
import CheckInCheckOut from './components/CheckInCheckOut'
import Delivery from './components/Delivery'
import FormsData from './components/FormsData'
import InfoValues from './components/InfoValues'
import PDFList from './components/PDF'
import PDVList from './components/PDV'
import Photos from './components/Photos'
import Products from './components/Products'
import SharedLink from './components/SharedLink'
import Signature from './components/Signature'
import { HeaderContainer, Title } from './styles'

function RegisterDetail() {
  const { id } = useParams()
  const history = useHistory()
  const registerId = id
  const [tabs, setTabs] = useState([])
  const [hasBonus, setHasBonus] = useState(false)
  const [costValue, setCostValue] = useState(null)
  const [reproveDescription, setReproveDescription] = useState(null)

  const [openReprove, setOpenReprove] = useState(false)
  const [onConfirm, setOnConfirm] = useState(false)
  const [onStatus, setOnStatus] = useState(null)
  const {
    register,
    getRegisterById,
    revertApprovedMission,
    onUpdateRegister,
    loadingRegister,
  } = useRegisters()
  const { getSharedLinkExtraDataByMissionUserId, extraData } = useSharedLink()

  const canEdit = register?.status === registerStatus.IN_ANALYSIS

  function onRegisterConfirm() {
    onChangeStatus({ status: onStatus })
    return setOnConfirm(false)
  }

  function onChangeValue(e, { value }) {
    return setCostValue(value)
  }

  function onSelectStatus(currentStatus) {
    setOnStatus(currentStatus)

    if (
      currentStatus === registerStatus.IN_CORRECTION ||
      currentStatus === registerStatus.REPROVED
    ) {
      return setOpenReprove(true)
    }

    return setOnConfirm(true)
  }

  function onChangeBonus(value) {
    return setHasBonus(Boolean(value))
  }

  function onChangeReproveDescription(e, { value }) {
    return setReproveDescription(value)
  }

  async function handleRegisterByRules() {
    let newPanes = []

    if (register !== null && !loadingRegister) {
      const missionsUsersId = register.missions_users_id

      newPanes = [
        ...newPanes,
        {
          menuItem: 'Avaliação',
          render: () => (
            <Tab.Pane attached={false}>
              <InfoValues
                register={register}
                costValue={costValue}
                hasBonus={hasBonus}
                onRefresh={onRefresh}
                loading={loadingRegister}
                onChangeBonus={onChangeBonus}
                onChangeValue={onChangeValue}
                onSelectStatus={onSelectStatus}
              />
            </Tab.Pane>
          ),
        },
        {
          menuItem: 'Atividades',
          render: () => (
            <Tab.Pane attached={false}>
              <Activities
                id={missionsUsersId}
                lat={register?.lat || 0}
                lng={register?.lng || 0}
                place={register?.place || 'Não Localizada'}
              />
            </Tab.Pane>
          ),
        },
      ]

      if (register.has_photo || register.has_import_image) {
        newPanes = [
          ...newPanes,
          {
            menuItem: 'Foto(s)',
            render: () => (
              <Tab.Pane attached={false}>
                <Photos
                  id={missionsUsersId}
                  userId={register.user_id}
                  missionId={register.mission_id}
                />
              </Tab.Pane>
            ),
          },
        ]
      }

      if (register.has_import_document) {
        newPanes = [
          ...newPanes,
          {
            menuItem: 'Arquivo(s) (.PDF)',
            render: () => (
              <Tab.Pane attached={false}>
                <PDFList id={missionsUsersId} />
              </Tab.Pane>
            ),
          },
        ]
      }

      if (register.has_forms) {
        newPanes = [
          ...newPanes,
          {
            menuItem: 'Dado(s) de Resposta(s)',
            render: () => (
              <Tab.Pane attached={false}>
                <FormsData id={missionsUsersId} canEdit={canEdit} />
              </Tab.Pane>
            ),
          },
        ]
      }

      if (register.has_collect) {
        newPanes = [
          ...newPanes,
          {
            menuItem: 'Coleta',
            render: () => (
              <Tab.Pane attached={false}>
                <Delivery
                  id={missionsUsersId}
                  place={register?.place}
                  missionName={register?.missionName}
                />
              </Tab.Pane>
            ),
          },
        ]
      }

      if (register.has_products) {
        newPanes = [
          ...newPanes,
          {
            menuItem: 'Produto(s) Vendido(s)',
            render: () => (
              <Tab.Pane attached={false}>
                <Products id={missionsUsersId} url={register.url_pdf_invoice} />
              </Tab.Pane>
            ),
          },
        ]
      }

      if (register.has_pos) {
        newPanes = [
          ...newPanes,
          {
            menuItem: 'Ponto(s) de Venda(s)',
            render: () => (
              <Tab.Pane attached={false}>
                <PDVList id={missionsUsersId} />
              </Tab.Pane>
            ),
          },
        ]
      }

      if (register.has_signature) {
        newPanes = [
          ...newPanes,
          {
            menuItem: 'Assinatura Digital',
            render: () => (
              <Tab.Pane attached={false}>
                <Signature id={missionsUsersId} />
              </Tab.Pane>
            ),
          },
        ]
      }

      if (register.check_in_check_out) {
        newPanes = [
          ...newPanes,
          {
            menuItem: 'Check-In / Check-Out',
            render: () => (
              <Tab.Pane attached={false}>
                <CheckInCheckOut
                  missionsUsersId={missionsUsersId}
                  place={register.place}
                  lat={register.lat}
                  lng={register.lng}
                />
              </Tab.Pane>
            ),
          },
        ]
      }
    }

    return setTabs(newPanes)
  }

  function onLoadTabSharedLink() {
    let newPanes = tabs

    if (register && register.has_shared_link) {
      newPanes = [
        ...newPanes,
        {
          menuItem: 'Compartilhamento de link',
          render: () => (
            <Tab.Pane attached={false}>
              <SharedLink
                sharedLink={register.shared_link}
                subId={register.sub_id}
                completed={extraData.completed}
                clicks={extraData.clicks}
                ctr={extraData.ctr}
              />
            </Tab.Pane>
          ),
        },
      ]
    }

    setTabs([...newPanes])
  }

  function onChangeStatus({ status }) {
    if (
      register.has_value_percentage &&
      !register.invoice_value &&
      register.status === registerStatus.IN_ANALYSIS &&
      status === registerStatus.PENDING
    ) {
      return message().error('Precisa inputar o valor na missão')
    }

    if (register.has_bonus && hasBonus === null) {
      return message().error(
        'Você precisa definir se o usuário realizou o bônus!'
      )
    }

    if (
      (status === registerStatus.IN_CORRECTION &&
        reproveDescription === null) ||
      (status === registerStatus.REPROVED && reproveDescription === null)
    ) {
      return message().error('Resposta de correção não pode estar vazia!')
    }

    setOnConfirm(false)
    setOpenReprove(false)

    const isTryRevertApprovedMission =
      registerStatus.APPROVED === register.status &&
      registerStatus.PENDING === status

    if (isTryRevertApprovedMission) {
      return revertApprovedMission(register.uid)
    }

    return onUpdateRegister({
      ...register,
      status,
      registerId,
      cost_value: costValue,
      confirm_bonus: hasBonus,
      has_reproved: reproveDescription,
    })
  }

  async function onRefresh() {
    await getRegisterById(registerId)
  }

  async function onLoadExtraDataAboutSharedLink() {
    if (register && register.has_shared_link) {
      await getSharedLinkExtraDataByMissionUserId(register.missions_users_id)
    }
  }

  useEffect(() => {
    onRefresh()
  }, []) //eslint-disable-line

  useEffect(() => {
    handleRegisterByRules()
  }, [register, loadingRegister]) //eslint-disable-line

  useEffect(() => {
    setHasBonus(register?.confirm_bonus)
    onLoadExtraDataAboutSharedLink()
  }, [register]) //eslint-disable-line

  useEffect(() => {
    onLoadTabSharedLink()
  }, [extraData]) //eslint-disable-line

  return (
    <Main>
      {loadingRegister ? <LoadingPage /> : null}

      {!loadingRegister && register !== null && (
        <>
          <HeaderContainer>
            <Button
              circular
              icon="angle left"
              onClick={() => history.goBack()}
            />
            <Title>
              {register.mu_key} - {register.missionName}
            </Title>
          </HeaderContainer>
          <Tab menu={{ secondary: true, pointing: true }} panes={tabs} />
        </>
      )}

      <ReproveList
        open={openReprove}
        status={onStatus}
        value={reproveDescription}
        onClose={() => setOpenReprove(false)}
        onChange={onChangeReproveDescription}
        onClick={() => onChangeStatus({ status: onStatus })}
      />

      <Confirm
        header="Alerta"
        open={onConfirm}
        cancelButton="Não"
        confirmButton="Sim"
        onCancel={() => setOnConfirm(false)}
        content="Tem certeza que deseja alterar esse registro?"
        onConfirm={onRegisterConfirm}
      />
    </Main>
  )
}

export default RegisterDetail
