/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'

import '~/assets/css/react-datepicker.css'

import t from 'prop-types'
import { Input, Card, Form, Button, Select, Dropdown } from 'semantic-ui-react'

import { useAuth } from '~/contexts/auth'
import useCategories from '~/hooks/useCategories'
import useCustomers from '~/hooks/useCustomers'
import useSubCategories from '~/hooks/useSubCategories'
import useUfsAndCitiesFilters from '~/hooks/useUfsAndCitiesFilters'
import { missionProgress } from '~/utils/types'

import { ListExports } from './Modals/ListExports'
import { SelectColumnsExport } from './Modals/SelectColumnsExport'

const orders = [
  { key: 0, value: 'm.created_at', text: 'Criado Em' },
  { key: 1, value: 'm.expired_at', text: 'Expira Em' },
]

const directions = [
  { key: 0, value: 'asc', text: 'Crescente' },
  { key: 1, value: 'desc', text: 'Descrescente' },
]

export function Filter({
  filters,
  isLoading,
  onChangeFilters,
  clearFilters,
  openEditModal,
  onOpenReplaceModal,
  openListEditionMissionBatch,
  columns,
  onSubmitExport,
}) {
  const { user } = useAuth()
  const role = user?.role_name

  const { getCustomers, customers } = useCustomers()
  const { getCategories, categoriesOptions } = useCategories()
  const { getSubCategories, subcategories } = useSubCategories()

  const [customersOptions, setCustomersOptions] = useState([])
  const [openExport, setOpenExport] = useState(false)
  const [openList, setOpenList] = useState(false)

  const { ufsOptions, citiesOptions, onChangeUFs, onChangeCities } =
    useUfsAndCitiesFilters(filters, onChangeFilters)

  const getOptionsArray = arr =>
    arr.map((item, index) => ({
      key: index,
      value: item.uid || item.uf || item,
      text: item.name || item.title || item,
    }))

  function onSubmitSelectedColumns(selectedColumns) {
    onSubmitExport({
      selectedColumns,
      ...filters,
    })

    setOpenExport(false)
  }

  useEffect(() => {
    Promise.all([getCustomers({ offset: 0, limit: 1000 }), getCategories()])
  }, []) //eslint-disable-line

  useEffect(() => {
    setCustomersOptions(getOptionsArray(customers))
  }, [customers])

  useEffect(() => {
    if (filters.categoryId) {
      getSubCategories({ categoryId: filters.categoryId })
    }
  }, [filters.categoryId]) //eslint-disable-line

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Buscar..."
              onChange={(e, { value }) => onChangeFilters({ search: value })}
            />
            <Form.Field
              control={Select}
              placeholder="Limite"
              selection
              value={filters.limit}
              options={[
                { key: 0, value: 10, text: 10 },
                { key: 1, value: 15, text: 15 },
                { key: 2, value: 25, text: 25 },
                { key: 3, value: 50, text: 50 },
                { key: 4, value: 100, text: 100 },
              ]}
              onChange={(e, { value }) => onChangeFilters({ limit: value })}
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Clientes"
              options={customersOptions}
              disabled={!customersOptions.length}
              value={filters.customerId}
              onChange={(e, { value }) =>
                onChangeFilters({ customerId: value })
              }
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Categoria"
              options={categoriesOptions}
              disabled={!categoriesOptions.length}
              value={filters.categoryId}
              onChange={(e, { value }) =>
                onChangeFilters({ categoryId: value })
              }
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Sub-Categoria"
              options={getOptionsArray(subcategories)}
              disabled={!subcategories.length}
              value={filters.missionsMainId}
              onChange={(e, { value }) =>
                onChangeFilters({ missionsMainId: value })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Field fluid disabled={!ufsOptions.length}>
              <Dropdown
                search
                selection
                multiple
                options={ufsOptions}
                placeholder="UFs"
                value={filters.ufs || []}
                onChange={(e, { value }) => {
                  console.log(value)
                  return onChangeUFs(value)
                }}
              />
            </Form.Field>
            <Form.Field
              fluid
              disabled={!citiesOptions.length || !filters?.ufs?.length}
            >
              <Dropdown
                search
                selection
                multiple
                options={citiesOptions}
                placeholder="Cidades"
                value={filters.cities || []}
                onChange={(e, { value }) => onChangeCities(value)}
              />
            </Form.Field>
            <Form.Field
              control={Select}
              search
              selection
              options={orders}
              placeholder="Coluna"
              value={filters.order}
              onChange={(e, { value }) =>
                onChangeFilters({
                  order: value,
                  iniCreate: null,
                  finCreate: null,
                  iniExpired: null,
                  finExpired: null,
                })
              }
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Ordenar Por"
              options={directions}
              value={filters.direction}
              onChange={(e, { value }) => onChangeFilters({ direction: value })}
            />
            {filters.order === 'm.expired_at' && (
              <>
                <Form.Field
                  selectsStart
                  control={DatePicker}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Inicio"
                  selected={filters.iniExpired}
                  startDate={filters.iniExpired}
                  endDate={filters.finExpired}
                  onChange={value =>
                    onChangeFilters({
                      iniExpired: value,
                      iniCreate: null,
                      finCreate: null,
                    })
                  }
                />
                <Form.Field
                  control={DatePicker}
                  id="endDate"
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Fim"
                  selected={filters.finExpired}
                  startDate={filters.iniExpired}
                  endDate={filters.finExpired}
                  onChange={value =>
                    onChangeFilters({
                      finExpired: value,
                      iniCreate: null,
                      finCreate: null,
                    })
                  }
                />
              </>
            )}
            {filters.order === 'm.created_at' && (
              <>
                <Form.Field
                  selectsStart
                  control={DatePicker}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Inicio"
                  selected={filters.iniCreate}
                  startDate={filters.iniCreate}
                  endDate={filters.finCreate}
                  onChange={value =>
                    onChangeFilters({
                      iniCreate: value,
                      iniExpired: null,
                      finExpired: null,
                    })
                  }
                />
                <Form.Field
                  control={DatePicker}
                  id="endDate"
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Fim"
                  selected={filters.finCreate}
                  startDate={filters.iniCreate}
                  endDate={filters.finCreate}
                  onChange={value =>
                    onChangeFilters({
                      finCreate: value,
                      iniExpired: null,
                      finExpired: null,
                    })
                  }
                />
              </>
            )}
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Progresso da Missão"
              options={missionProgress}
              value={filters.missionProgress}
              onChange={(e, { value }) =>
                onChangeFilters({ missionProgress: value })
              }
            />
          </Form.Group>
        </Form>
      </Card.Content>
      <Card.Content>
        <Button
          icon="undo"
          color="grey"
          content="Limpar Filtros"
          labelPosition="left"
          onClick={clearFilters}
          loading={isLoading}
        />

        {role === 'Admin' && (
          <Button
            icon="edit"
            color="blue"
            content="Editar Missões"
            labelPosition="left"
            onClick={openEditModal}
            loading={isLoading}
          />
        )}
        {role === 'Admin' && (
          <Button
            icon="repeat"
            color="orange"
            loading={isLoading}
            labelPosition="left"
            content="Recompor Missões"
            onClick={onOpenReplaceModal}
          />
        )}

        {role === 'Admin' && (
          <Button
            icon="list"
            color="gray"
            loading={isLoading}
            labelPosition="left"
            content="Ver edições de missões em massa"
            onClick={openListEditionMissionBatch}
          />
        )}

        {role === 'Admin' && (
          <>
            <Dropdown
              text="Exportar"
              icon="upload"
              button
              labeled
              className="icon"
              floating
              loading={isLoading}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  icon="upload"
                  text="Exportar Missões Lançadas"
                  onClick={() => setOpenExport(true)}
                />
                <Dropdown.Item
                  icon="list"
                  text="Ver Lista de Missões Exportadas"
                  onClick={() => setOpenList(true)}
                />
              </Dropdown.Menu>
            </Dropdown>
            <SelectColumnsExport
              columns={columns}
              open={openExport}
              onClose={() => setOpenExport(false)}
              onSubmitSelectedColumns={onSubmitSelectedColumns}
            />

            <ListExports open={openList} onClose={() => setOpenList(false)} />
          </>
        )}
      </Card.Content>
    </Card>
  )
}

Filter.propTypes = {
  filters: t.objectOf(t.any),
  isLoading: t.bool,
  onChangeFilters: t.func,
  clearFilters: t.func,
  openEditModal: t.func,
  onOpenReplaceModal: t.func,
  openListEditionMissionBatch: t.func,
  columns: t.arrayOf(t.string),
  onSubmitExport: t.func,
}
