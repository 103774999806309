export const COLUMNS = [
  'Cliente',
  'Situação',
  'Total missões',
  'Total missões atualizadas',
  'Período',
  'Iniciou em',
  'Terminou em',
  'Criado Em',
]

export const EMPTY_TEXT = {
  text: 'Não há nenhuma register de edição de missões em massa',
}
export const QUEUE_UPDATE_MISSION_STATUS_TO_USER = {
  DONE: 'Finalizado',
  IN_PROGRESS: 'Em progresso',
  FAILED: 'Falhou',
  PENDENT: 'PENDENT',
}

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss'

export const QUEUE_UPDATE_MISSION_BATCH_STATUS = {
  PENDENT: 'PENDENT',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  FAILED: 'FAILED',
}

export const EXPORT_STATUS = {
  PENDENT: 'Pendente',
  DONE: 'Concluído',
}
