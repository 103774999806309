import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { ExcelRenderer } from 'react-excel-renderer'

import { format } from 'date-fns'
import {
  Card,
  Button,
  Confirm,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useCustomers from '~/hooks/useCustomers'
import useExportationMissionImported from '~/hooks/useExportationMissionImported'
import useImports from '~/hooks/useImports'
import Main from '~/pages/Main'
import Message from '~/utils/messages'
import Spreadsheet from '~/utils/spreadsheet'

import Customers from './components/Customers'
import Files from './components/Files'
import QueueExportationMissionsImported from './components/QueueExportationMissionsImported'

const INTERVAL_MILLISECONDS = 10000

function Imports(props) {
  const {
    imports,
    loadingImports,
    loadingImportsCreated,
    getImports,
    onRemoveImportById,
    processSpreadSheet,
  } = useImports()

  const { getAllCustomers, customers, loadingCustomers } = useCustomers()

  const [rows, setRows] = useState([])
  const [name, setName] = useState(null)
  const [open, setOpen] = useState(false)
  const [fields, setFields] = useState([])
  const [importId, setImportId] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const [activitiesId, setActivitiesId] = useState([])
  const [formsId, setFormsId] = useState([])

  const {
    exportationsRecently,
    getExportationsRecently,
    exportMissionsImported,
  } = useExportationMissionImported()

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      if (
        file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        return Message().error('Arquivo inválido!')
      }

      ExcelRenderer(file, async (err, resp) => {
        if (err) {
          return Message().error(err)
        }

        if (resp.rows.length > 4000) {
          return Message().error('Arquivo não pode ter mais de 4000 linhas')
        }

        const rowsDroped = await Spreadsheet.mapRowsToObject(resp.rows)

        setName(file.name)
        setRows(rowsDroped)

        let activitiesId = {}
        let formsId = {}

        resp.rows.forEach(item => {
          if (item?.activity && !activitiesId[item.activity]) {
            activitiesId[item.activity] = true
          }

          if (item?.form && !formsId[item.form]) {
            formsId[item.form] = true
          }
        })

        activitiesId = Object.keys(activitiesId)
        formsId = Object.keys(formsId)
        setActivitiesId(activitiesId)
        setFormsId(formsId)
      })
    })
  }, [])

  function onChange(e, { value }) {
    return setCustomerId(value)
  }

  function onChangeFields(e, { options, value }) {
    console.log(fields)
    console.log(rows)
    console.log(options)
    const newFields = fields.find(i => i.label === rows[options[0].col])
    newFields.value = value
    setFields(fields)
  }

  function submitImport() {
    if (!customerId) {
      return Message().error('Escolha um cliente!')
    }

    if (rows.length === 0) {
      return Message().error('Adicione um arquivo!')
    }

    return processSpreadSheet(rows, name, customerId, activitiesId, formsId)
  }

  function confirmRemove(id) {
    setImportId(id)
    return setOpen(true)
  }

  function renderItem(item, index) {
    return (
      <Table.Row key={`k-${index}`}>
        <Table.Cell>{item.uid}</Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>
          {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
        </Table.Cell>
        <Table.Cell>
          {item.total_lines === item.processed_lines
            ? 'Completo'
            : 'Processando...'}
        </Table.Cell>
        <Table.Cell>{item.total_lines}</Table.Cell>
        <Table.Cell>{item.processed_lines}</Table.Cell>
        <Table.Cell>
          <Dropdown icon="ellipsis vertical">
            <DropdownMenu direction="left">
              <DropdownItem
                onClick={() => exportMissionsImported(item)}
                icon="cloud download"
                text="Exportar missões"
              />
              <DropdownItem
                onClick={() => confirmRemove(item.uid)}
                icon="remove"
                text="Remover"
              />
            </DropdownMenu>
          </Dropdown>
        </Table.Cell>
      </Table.Row>
    )
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleMap = item => ({
    key: item.uid,
    value: item.uid,
    text: item.name.toUpperCase(),
  })

  const loadMoreImportFiles = event => {
    event.preventDefault()
    getImports()
  }

  const startMonitorExportations = () =>
    setInterval(async () => {
      getExportationsRecently()
    }, INTERVAL_MILLISECONDS)

  useEffect(() => {
    getAllCustomers()
    getImports()
    getExportationsRecently()
    const interval = startMonitorExportations()
    return () => clearInterval(interval)
  }, []) //eslint-disable-line

  return (
    <Main {...props}>
      <Card fluid>
        <Card.Content>
          <Customers
            onChange={onChange}
            loading={loadingCustomers}
            values={customers.map(handleMap)}
          />
          <Files
            rows={rows}
            onChange={onChangeFields}
            isDragActive={isDragActive}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
          />
        </Card.Content>
        {rows.length > 0 && (
          <Card.Content extra>
            <Button
              positive
              onClick={submitImport}
              loading={loadingImportsCreated}
              disabled={loadingImportsCreated}
            >
              Importar
            </Button>
          </Card.Content>
        )}
      </Card>

      {loadingImportsCreated && (
        <Card fluid>
          <Card.Content>
            <h4>Importando arquivo...</h4>
          </Card.Content>
        </Card>
      )}

      <QueueExportationMissionsImported exportations={exportationsRecently} />
      <TableComponent
        data={imports}
        QueueExportationMissionsImported
        renderItem={renderItem}
        isLoading={loadingImportsCreated || loadingImports}
        columns={[
          'ID',
          'NOME ARQUIVO',
          'CRIADO EM',
          'STATUS',
          'N˚ LINHAS',
          'LINHAS PROCESSADAS',
          'ACESSO',
        ]}
      />
      <Button type="primary" onClick={loadMoreImportFiles}>
        Carregar mais
      </Button>

      <Confirm
        open={open}
        header="Alerta"
        confirmButton="Sim"
        cancelButton="Cancelar"
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          onRemoveImportById(importId)
          return setOpen(false)
        }}
        content="Tem certeza que deseja remover este arquivo?"
      />
    </Main>
  )
}

export default Imports
