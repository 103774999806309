import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Loader } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import useCategories from '~/hooks/useCategories'
import Main from '~/pages/Main'

import CreateCategories from '../components/Create'

import 'react-quill/dist/quill.snow.css'

export default function Detail(props) {
  const { categoryId } = useParams()

  const uploadRef = useRef()
  const { onUpdateCategory, loadingCategories, category, getCategoryById } =
    useCategories()

  const [categoryData, setCategoryData] = useState({
    name: '',
    status: false,
    description: '',
    ...category,
  })

  function onChange(key, value) {
    return setCategoryData(prevState => ({ ...prevState, [key]: value }))
  }

  function handleChangeCheckbox(e, { id, checked }) {
    return setCategoryData({ ...category, [id]: checked })
  }

  function uploadImage(e) {
    const file = e.target.files[0]
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = event => {
      setCategoryData(prevState => ({
        ...prevState,
        avatar: {
          data: file,
          src: event.target.result,
        },
      }))
    }
  }

  useEffect(() => {
    getCategoryById(categoryId)
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!loadingCategories) {
      setCategoryData(prevState => ({
        ...prevState,
        ...category,
        has_private_customer: category?.customer_id?.length > 0,
      }))
    }
  }, [category]) //eslint-disable-line

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/categories',
      name: 'Categorias',
    },
    {
      active: true,
      hasLink: false,
      name: category.name,
    },
  ]

  if (loadingCategories) {
    return (
      <Main {...props}>
        <div>
          <Loader active inline="centered" />
        </div>
      </Main>
    )
  }

  return (
    <Main {...props}>
      <Breadcrumbs items={breadcrumbsList} />
      <CreateCategories
        show
        myRef={uploadRef}
        onChange={onChange}
        category={categoryData}
        uploadImage={uploadImage}
        isLoading={loadingCategories}
        onClick={() => uploadRef.current.click()}
        handleChangeCheckbox={handleChangeCheckbox}
        create={() => onUpdateCategory({ ...categoryData, uid: categoryId })}
      />
    </Main>
  )
}
